"use client";
import Image from "next/image";
import Link from "next/link";
import {Box, Button, Heading, VStack} from "@chakra-ui/react";
import notFoundImage from "public/404/notFound.jpg";
import {PAGE_NOT_FOUND_CONSTANTS} from "./pageNotFound.constants";

const PageNotFound = () => {
  return (
    <VStack
      px={{base: 6, sm: 8, md: 12, lg: 24}}
      py={{base: 6, sm: 8, md: 12}}
      spacing={4}
    >
      <Image
        alt=""
        height={"200"}
        placeholder="blur"
        src={notFoundImage}
        style={{borderRadius: "8px"}}
        width={"300"}
      />
      <Heading textAlign="center">{PAGE_NOT_FOUND_CONSTANTS.HEADING_TITLE}</Heading>
      <Box textAlign="center">{PAGE_NOT_FOUND_CONSTANTS.DESC_SUBTITLE1}</Box>
      <Button
        as={Link}
        href="/"
        size="lg"
        minW={{base: "full", sm: "10rem"}}
        variant={"outline"}
      >
        {PAGE_NOT_FOUND_CONSTANTS.HOME_BUTTON_TEXT}
      </Button>
    </VStack>
  );
};

export default PageNotFound;
